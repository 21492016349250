import './scss/theme.scss';
import './js/lightcase.min';

jQuery(document).ready(function (jQuery) {
	//Elements to inject
	var elementsToInject = document.querySelectorAll('.inject-me');
	// Options
	var injectorOptions = {
		evalScripts: 'once'
	};
	// Trigger the injection
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}

	//HEADER SCROLL
	if (jQuery('.home header[role="banner"]').length > 0) {
		jQuery(window).scroll(function () {
			if (jQuery(document).scrollTop() > 115) {
				jQuery('header[role="banner"]').addClass('scrollHead');
			} else {
				jQuery('header[role="banner"]').removeClass('scrollHead');
			}
		});
	}

	//lightcase for galleries
	if (jQuery('.wp-block-gallery').length > 0){
		jQuery.each(jQuery('.blocks-gallery-grid a'), function (index, item) {
			jQuery(item).attr('data-rel', 'lightcase:myCollection');
		});
		jQuery('a[data-rel="lightcase:myCollection"]').lightcase({});
	}

});

jQuery.fn.exists = function (callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}

	return this;
};
